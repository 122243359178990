import Vue from "vue";
import { API } from "../../services/API"
//import { maskCPFCNPJ, maskTELEFONE, CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../services/funcoes";

function initialState() {

  return {
    dados                         : [],
    acao                          : "C", // E=Edicao, I=Inclusão, C=Consulta
    ged_edicao                    : {},  // ged Selecionada para Edição
    ged_selecionado               : {}, 
    dialog_cadastro               : false,
    search                        : null,
    currentPage                   : 1,
    count_dados                   : null,
    filtro                        : null,
    acao_abas                     : false,

    // dialogConjuge                 : false,
    // dialogConjugeExcluir          : false,
    // dialogEnderecosCadastro       : false,
    // dialogEnderecosExclusao       : false,
    // dialogDocumentacaoCadastro    : false,
    // dialogDocumentacaoExclusao    : false,
    // dialogContatosCadastro        : false,
    // dialogContatosExclusao        : false,
    // dialogDadosBancariosCadastro  : false,
    // dialogDadosBancariosExclusao  : false,
    // dialogSociosCadastro          : false,
    // dialogSociosExclusao          : false,
    // dialog_excluir                : false,  
    // dialog_excluir_tipo           : false,  
    // dialog_excluir_registro       : false,  

    // abas: [
    //   ['mdi-view-dashboard-outline', 'Dados Básicos', 5, 0],
    //   ['mdi-text-box-outline', 'Documentos', 0, 1],
    //   ['mdi-security', 'Privilégios', 0, 2],
    // ],
        
  }
}

var vm_store_Ged = new Vue({

  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },

    // Tela de Documentos ==================================================================
    async getGed(p_params) {
      const resposta = await API.get("get_ged", {
                                                  params: {
                                                    ...p_params
                                                  },
                                                }
                                      );
                                     
      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          return resposta.data.result
        }
      }
    },

    async deleteGed(body) {
      // console.log(body);
      // return
      const resposta = await API.delete(`ged_tipo_documento/${body.cod_ged}`)

      const { data } = resposta;
      if (resposta.status == 200) 
        return data.result;
      else
        return data;
    
    },

    async postGed(body) {
      const resp = await API.post(`/ged`, JSON.stringify(body));
    
      const { data } = resp;
      if (resp.status == 200) 
        return data.result;
      else
        return data;
    },

    
  },

})

export default vm_store_Ged
